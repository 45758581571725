<template>
  <div class="footer">
    <div class="top">
      <h2>关注我们</h2>
      <div class="wxicon">
        <img
          src="https://static.xyz10.com/static%2F2022homePage%2F%E5%BE%AE%E4%BF%A1%E7%9A%84%E5%89%AF%E6%9C%AC.png"
          alt=""
        />
      </div>
    </div>
    <div class="br"></div>
    <div class="content">
      <div class="left">
        <div class="list_box">
          <div class="list">
            <div class="tittle">核心产品</div>
            <div class="text_list">
              <!-- <div class="text">当午</div> -->
              <a class="text" href="https://chuhe.xyz10.com/">除和</a>
              <a class="text" href="https://www.9kuo.com/">当午</a>
              <a class="text" href="https://fluxflow.xyz10.com/">积流</a>
              <!-- <router-link to="/zhengwu">正午</router-link> -->
              <!-- <div class="text">正午</div> -->
            </div>
          </div>
          <div class="list">
            <div class="tittle">关于我们</div>
            <div class="text_list">
              <a class="text" href="#">企业文化</a>
              <a class="text" href="#">可持续发展</a>
              <a class="text" href="#">管理层信息</a>
            </div>
          </div>
          <div class="list">
            <div class="tittle">新闻与活动</div>
            <div class="text_list">
              <a class="text" href="#">新闻</a>
              <a class="text" href="#">活动</a>
              <a class="text" href="#">媒体</a>
            </div>
          </div>
          <div class="list">
            <div class="tittle">联系我们</div>
            <div class="text_list">
              <a class="text" href="#">客服服务</a>
              <a class="text" href="#">合作洽谈</a>
              <a class="text" href="#">十域采购</a>
            </div>
          </div>
          <div class="list">
            <div class="tittle">法律信息</div>
            <div class="text_list">
              <a class="text" href="#">服务协议</a>
              <a class="text" href="#">隐私政策</a>
              <a class="text" href="#">知识产权</a>
            </div>
          </div>
        </div>
        <div class="footer_miaoshu">
          <div class="icon">
            <img
              src="https://static.xyz10.com/static%2F2022homePage%2F%E5%B7%B2%E5%AE%9A%E4%BD%8D_local%20(1)%E7%9A%84%E5%89%AF%E6%9C%AC.png"
              alt=""
            />
          </div>
          杭州余杭区未来科技城
          <div class="icon">
            <img
              src="https://static.xyz10.com/static%2F2022homePage%2F%E9%82%AE%E4%BB%B6_mail.png"
              alt=""
            />
          </div>
          b@xyz10.com
          <div class="icon">
            <img
              src="https://static.xyz10.com/static%2F2022homePage%2F%E7%94%B5%E8%AF%9D_phone-telephone%E7%9A%84%E5%89%AF%E6%9C%AC.png"
              alt=""
            />
          </div>
          17130048455
        </div>
        <div class="foot_bottom">
          <!-- <div>法律声明 ｜ 友情链接 ｜ 网站地图</div> -->
          <a
            class="footer_miaoshu_right"
            href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010402004247"
          >
            浙公网安备 33010402004247号
          </a>
          <a class="footer_miaoshu_right" href="https://beian.miit.gov.cn">
            备案号: 浙ICP备17056998号-1 &nbsp;&nbsp;
          </a>
          <div class="footer_miaoshu_right">
            Copyright @2017 杭州十域科技有限公司版权所有
          </div>
        </div>
      </div>
      <div class="right">
        <div class="QRcode">
          <img
            src="https://static.xyz10.com/static/2022homePage/QRcode.png"
            alt=""
          />
        </div>
        <div class="name">
          <img
            src="https://static.xyz10.com/static/2022homePage/010-07.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goto() {
      window.open("https://beian.miit.gov.cn");
    },
    goto1() {
      window.open(
        "https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010402004247"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  transform: translateZ(100px);
  height: 520px;
  color: #fff;
  background-color: #1d2a36;
  padding: 0 12.5vw 0 12.5vw;
  a {
    display: block;
    text-decoration: none;
    color: #fff;
  }
  .top {
    padding-top: 42px;
    height: 60px;
    display: flex;
    h2 {
      font-family: PingFangSC-Semibold;
      font-size: 24px;
      color: #fff;
    }
    .wxicon {
      margin-top: 5px;
      margin-left: 47px;
      width: 29px;
      img {
        display: block;
        width: 100%;
      }
    }
  }
  .br {
    width: 100%;
    height: 1px;
    margin-top: 40px;
    background-color: #fff;
    opacity: 0.4;
  }
  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .left {
      width: 55vw;
      min-width: 800px;
      .list_box {
        display: flex;
      }
      .list {
        margin-right: 4vw;
        margin-top: 36px;
        .tittle {
          font-family: PingFangSC-Semibold;
          font-size: 18px;
        }
        .text {
          font-family: PingFangSC-Semibold;
          font-size: 14px;
          opacity: 0.7;
          margin-bottom: 37px;
        }
        .text_list {
          margin-top: 44px;
          a {
            display: block;
          }
        }
      }
      .footer_miaoshu {
        margin-top: 10px;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        // font-weight: 900;
        opacity: 0.6;
        display: flex;
        line-height: 14px;
        .icon {
          width: 14px;
          margin-right: 8px;
          margin-left: 35px;
          &:first-child {
            margin-left: 0;
          }
          img {
            width: 100%;
            display: block;
          }
        }
      }
      .foot_bottom {
        display: flex;
        margin-top: 30px;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        // font-weight: 900;
        opacity: 0.6;
        .footer_miaoshu_right {
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }
    .right {
      margin-top: 36px;
      .QRcode {
        width: 210px;
        height: 210px;
        // margin-right: 30px;
        margin: 0 auto;
        img {
          width: 100%;
          display: block;
        }
      }
      .name {
        margin: 75px auto;
        width: 158px;
        img {
          width: 100%;
          display: block;
        }
      }
    }
  }
}
</style>
